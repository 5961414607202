// IDirveCam colors

// primary

$light-primary: #262756;
$light-primary-contrast: #ffffff;

$dark-primary: #5f61d1;
$dark-primary-contrast: #ffffff;

// accent/secondary

$light-accent: #e30613;
$light-accent-contrast: #ffffff;

$dark-accent: #e30613;
$dark-accent-contrast: #ffffff;

// common

$page-background: #2627562f;
